import httpClient from '../wrappers/axios';
import {authHeader} from '../_helpers';

export const RepairService = {
  getEntries,
  newEntry,
  updateEntry,
  deleteEntry,
  fullTextSearchByEntries
};

async function getEntries(options) {
  const {sortBy, sortDesc, page, itemsPerPage} = options;
  const requestOptions = {
    headers: authHeader()
  };
  const _sortDesc = (typeof sortDesc[0])
  const sort = sortBy.join(',');
  let _sort = sort;

  if (_sortDesc == 'boolean') {
    if (_sort && sortDesc[0]) {
      _sort = `-${sort}`
    }
  }

  if (_sortDesc == 'boolean') {
    if (_sort && !sortDesc[0]) {
      _sort = `${sort}`
    }
  }

  if (_sortDesc == undefined) {
    _sort = `${sort}`
  }

  return await httpClient.get(`/repair?pageSize=${itemsPerPage}&page=${page}&sort=${_sort}`, requestOptions)
}

async function newEntry(data) {
  const requestOptions = {
    headers: authHeader()
  };
  return await httpClient.post(`/repair`, data, requestOptions)
}

async function updateEntry(data) {
  try {
    return await httpClient.put(`/repair/${data._id}`, data, {
      headers: {
        ...authHeader()
      },
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

async function deleteEntry(data) {
  try {
    return await httpClient.delete(`/repair/${data._id}`, {
      headers: {
        ...authHeader()
      },
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

async function fullTextSearchByEntries(query) {
  const requestOptions = {
    headers: authHeader()
  };
  try {
    return await httpClient.get(`/repair&search?text=${query}`, requestOptions)
  } catch (err) {
    return Promise.reject(err);
  }
}