<template>
  <v-dialog
    v-model="_control"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollablemax-width="500px"
  >
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="$emit('close-btn', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          title === 'new' ? 'Новая запись' : 'Редактирование записи'
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="Наименование"
                name="name"
                :value="name"
                @input="(v) => updateName(v)"
              ></v-text-field>
              <!-- $event.target.value -->
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                label="РЭА №"
                :value="deviceId"
                @input="(v) => updateDeviceId(v)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                :items="stats"
                label="Статус"
                :value="status"
                @input="(v) => updateStatus(v)"
              >
                <template v-slot:item="{ item }">
                  <span>{{ statsToRus[item] }}</span>
                </template></v-select
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                outlined
                label="Заметка"
                :value="note"
                @input="(v) => updateNote(v)"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="filename in images"
              :key="filename"
              class="d-flex child-flex"
              cols="4"
            >
              <v-badge bordered color="error" overlap>
                <v-icon
                  slot="badge"
                  style="z-index: 1"
                  @click="deleteFile(filename)"
                >
                  mdi-delete
                </v-icon>
                <v-img
                  :src="`${apiServerUrl}/upload/${id}/${filename}`"
                  :lazy-src="`${apiServerUrl}/upload/${id}/${filename}`"
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-badge>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-file-input
                chips
                counter
                multiple
                show-size
                truncate-length="15"
                :value="files"
                @change="(v) => updateFiles(v)"
              ></v-file-input>
            </v-col>
            <!-- <v-col cols="12" sm="2" md="2">
              <v-btn
                :loading="uploadFileLoading"
                :disabled="uploadFileLoading"
                color="blue-grey"
                class="ma-2 white--text"
                @click="uploadFileLoader = 'uploadFileLoading'"
              >
                Upload
                <v-icon right dark> mdi-cloud-upload </v-icon>
              </v-btn>
            </v-col> -->
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('close-btn', false)">
          Отмена
        </v-btn>
        <v-btn color="blue darken-1" text @click="$emit('save-btn', true)">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import { FileService } from '../_services';
const apiServerUrl = process.env.VUE_APP_BACKEND_URI;
export default Vue.extend({
  inheritAttrs: false,
  name: 'EntryDialog',
  props: [
    'name',
    'deviceId',
    'status',
    'note',
    'title',
    'control',
    'close-btn',
    'save-btn',
    'files',
    'images',
    'id',
  ],
  emits: [
    'updateName',
    'updateDeviceId',
    'updateStatus',
    'updateNote',
    'updateFiles',
    'deleteFileFromEntry',
  ],
  data: () => ({
    apiServerUrl: apiServerUrl,
    uploadFileLoader: null,
    uploadFileLoading: false,
    statsToRus: {
      DONOR: 'ДОНОР',
      RESTORED: 'ВОССТАНОВЛЕН',
      RESTORED_AFTER_REPAIR: 'ВОССТАНОВЛЕН ПОСЛЕ РЕМОНТА',
      ON_DIAGNOSTICS: 'НА ДИАГНОСТИКЕ',
      UNDER_REPAIR: 'В РЕМОНТЕ',
      WAITING_FOR_SPARE_PARTS: 'ОЖИДАНИЕ ЗАПЧАСТЕЙ',
    },
    stats: [
      'DONOR',
      'RESTORED',
      'RESTORED_AFTER_REPAIR',
      'ON_DIAGNOSTICS',
      'UNDER_REPAIR',
      'WAITING_FOR_SPARE_PARTS',
    ],
  }),
  computed: {
    _control: {
      get() {
        return this.control;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    uploadFileLoader() {
      const l = this.uploadFileLoader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.uploadFileLoader = null;
    },
  },
  mounted() {},
  methods: {
    async getImage(path) {
      //   :src="getImage(`${id}/${filename}`)"
      // :src="`${apiServerUrl}/upload/${id}/${filename}`"
      // :lazy-src="`${apiServerUrl}/upload/${id}/${filename}`"
      if (path.id) {
        console.log(path);
        const result = await FileService.getImage(
          `${path.id}/${path.filename}`
        );
        console.log(result);
      }
      //
      //
    },
    deleteFile(fileName) {
      this.$emit('deleteFileFromEntry', fileName);
    },
    updateName(value) {
      this.$emit('updateName', value);
    },
    updateDeviceId(value) {
      this.$emit('updateDeviceId', value);
    },
    updateStatus(value) {
      this.$emit('updateStatus', value);
    },
    updateNote(value) {
      this.$emit('updateNote', value);
    },
    updateFiles(value) {
      this.$emit('updateFiles', value);
    },
  },
  created() {},
});
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
