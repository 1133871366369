<template>
  <v-main>
    <v-container fluid>
      <div>
        <v-data-table
          :headers="headers"
          :items="getRepairRows"
          :options.sync="options"
          :items-per-page="getRepairPageSize"
          :server-items-length="getRepairTotal"
          :loading="loading"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Устройства</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-autocomplete
                v-model="search"
                :loading="isLoading"
                :items="getRepairRows"
                :search-input.sync="syncSearch"
                cache-items
                class="mx-4"
                flat
                hide-no-data
                hide-details
                item-text="name"
                label="What state are you from?"
              ></v-autocomplete>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark class="mb-2" @click="newEntry()">
                Новая запись
              </v-btn>
              <EntryDialog
                :control="newEntryDialogVisibleControl"
                :title="title"
                @close-btn="newEntryDialogVisibleControl = $event"
                @save-btn="saveNewEntry"
                :name="name"
                @updateName="updateName"
                :deviceId="deviceId"
                @updateDeviceId="updateDeviceId"
                :status="status"
                @updateStatus="updateStatus"
                :note="note"
                @updateNote="updateNote"
                :files="files"
                @updateFiles="updateFiles"
                :images="images"
              />
              <EntryDialog
                :control="editEntryDialogVisibleControl"
                :title="title"
                @close-btn="editEntryDialogVisibleControl = $event"
                @save-btn="updateEntry"
                :name="name"
                @updateName="updateName"
                :deviceId="deviceId"
                @updateDeviceId="updateDeviceId"
                :status="status"
                @updateStatus="updateStatus"
                :note="note"
                @updateNote="updateNote"
                :files="files"
                @updateFiles="updateFiles"
                :images="images"
                :id="id"
                @deleteFileFromEntry="deleteFileFromEntry"
              />
            </v-toolbar>
          </template>
          <!-- eslint-disable -->
          <template v-slot:item.createdAt="{ item }">
            <span>{{ new Date(item.createdAt).toDateString() }}</span>
          </template>
          <template v-slot:item.updatedAt="{ item }">
            <span>{{ new Date(item.updatedAt).toDateString() }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editEntry(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import Vue from 'vue';
import EntryDialog from '../components/EntryDialog.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default Vue.extend({
  name: 'RepairPage',
  components: {
    EntryDialog,
  },
  data: () => ({
    errorBar: false,
    messageBar: false,
    isLoading: false,
    loading: true,
    options: {},
    newEntryDialogVisibleControl: false,
    editEntryDialogVisibleControl: false,
    title: '',
    id: '',
    name: '',
    deviceId: '',
    status: '',
    images: [],
    search: null,
    syncSearch: null,
    files: null,
    note: 'Заметки нет',
    headers: [
      {
        text: 'Наименование',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'РЭА №',
        value: 'deviceId',
      },
      { text: 'Заметка', value: 'note' },
      { text: 'Создана', value: 'createdAt' },
      { text: 'Обновлена', value: 'updatedAt' },
      { text: 'Статус', value: 'status' },
      { text: 'Действия', value: 'actions', sortable: false },
    ],
  }),
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    async syncSearch(val) {
      // val && val !== this.search && this.querySelections(val)
      // Items have already been loaded
      if (this.getRepairRows.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      await this.fullTextSearchByRepairEntries(val);
    },
  },
  computed: {
    ...mapGetters({
      getRepairRows: 'getRepairRows',
      getRepairPageSize: 'getRepairPageSize',
      getRepairTotal: 'getRepairTotal',
    }),
    show: {
      get() {
        return this.getShowDescriptions;
      },
      set(v) {
        this.setShowDescription(v);
      },
    },
  },
  methods: {
    ...mapActions({
      alert: 'message',
      error: 'error',
      getRepairEntries: 'getRepairEntries',
      newRepairEntry: 'newRepairEntry',
      updateRepairEntry: 'updateRepairEntry',
      deleteRepairEntry: 'deleteRepairEntry',
      fullTextSearchByRepairEntries: 'fullTextSearchByRepairEntries',
      uploadFiles: 'uploadFiles',
      deleteFile: 'deleteFile',
    }),
    ...mapMutations({}),
    updateName(value) {
      this.name = value;
    },
    updateDeviceId(value) {
      this.deviceId = value;
    },
    updateStatus(value) {
      this.status = value;
    },
    updateNote(value) {
      this.note = value;
    },
    async updateFiles(value) {
      this.files = value;
      let formData = null;
      if (this.title === 'edit') {
        if (this.files) {
          formData = new FormData();
          for (let file of this.files) {
            formData.append('files', file, file.name);
          }
          const response = await this.uploadFiles({
            id: this.id,
            files: formData,
          });
          response.data.map((file) => this.images.push(file.meta.filename));
          this.files = [];
          await this.getRepairEntries(this.options);
        }
      }
    },
    async deleteFileFromEntry(fileName) {
      try {
        await this.deleteFile({
          id: this.id,
          file: fileName,
        });
      } catch (error) {
        console.log(error);
      }
      const filteredImages = this.images.filter((_file) => _file !== fileName);
      console.log(filteredImages)
      this.images = filteredImages
    },
    statusColor(value) {
      return value ? 'green' : 'red';
    },
    statusText(value) {
      return value ? 'Открыт' : 'Закрыт';
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async getDataFromApi() {
      this.loading = true;
      await this.getRepairEntries(this.options);
      this.loading = false;
    },
    newEntry() {
      this.title = 'new';
      this.name = '';
      this.status = '';
      this.deviceId = '';
      this.note = '';
      this.files = [];
      this.images = [];
      this.newEntryDialogVisibleControl = true;
    },
    async saveNewEntry() {
      this.loading = true;
      let formData = null;
      const response = await this.newRepairEntry({
        name: this.name,
        status: this.status,
        deviceId: this.deviceId,
        note: this.note,
      });

      const id = response.data._id;
      if (this.files.length) {
        formData = new FormData();
        for (let file of this.files) {
          formData.append('files', file, file.name);
        }
        await this.uploadFiles({
          id: id,
          files: formData,
        });
      }
      await this.getRepairEntries(this.options);
      this.name = '';
      this.status = '';
      this.deviceId = '';
      this.note = '';
      this.files = [];
      this.images = [];
      this.loading = false;
      this.newEntryDialogVisibleControl = false;
    },
    async editEntry(item) {
      this.title = 'edit';
      this.id = item._id;
      this.name = item.name;
      this.deviceId = item.deviceId;
      this.status = item.status;
      this.note = item.note;
      this.images = item.images;
      this.editEntryDialogVisibleControl = true;
    },
    async updateEntry() {
      this.loading = true;
      await this.updateRepairEntry({
        _id: this.id,
        name: this.name,
        status: this.status,
        deviceId: this.deviceId,
        note: this.note,
      });
      // if (this.files) {
      //   formData = new FormData();
      //   for (let file of this.files) {
      //     formData.append('files', file, file.name);
      //   }
      //   await this.uploadFiles({
      //     id: this.id,
      //     files: formData,
      //   });
      // }
      this.name = '';
      this.status = '';
      this.deviceId = '';
      this.note = '';
      this.files = [];
      this.images = [];
      await this.getRepairEntries(this.options);
      this.loading = false;
      this.editEntryDialogVisibleControl = false;
    },
    async deleteItem(item) {
      this.loading = true;
      await this.deleteRepairEntry(item);
      await this.getRepairEntries(this.options);
      this.loading = false;
    },
  },
  async mounted() {},
});
</script>

<style lang="css" scoped>
#avatar {
  /* background-color: #009688;
    background-size: cover; */
  position: absolute;
  top: 190px;
}
</style>
