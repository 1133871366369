<template>
  <div class="text-center">
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'App',
  components: {},
  data: () => ({}),
  methods: {},
  created() {
    Vue.$log.debug('App started');
  },
});
</script>

<style>
#app .v-bottom-navigation .v-btn {
  height: inherit !important;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
