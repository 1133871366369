/* eslint-disable */
import Vue from 'vue';
import Vuex, {mapState} from 'vuex';
import {jwtDecode} from "jwt-decode";
import {
  UsersService,
  AuthService,
  RepairService,
  FileService
} from '../_services';

Vue.use(Vuex);

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? {status: {loggedIn: true}, user}
  : {
    status: {loggedIn: false},
    user: null,
  };
const state = {
  auth: initialState,
  title: '',
  addresses: [],
  settings: {
    displayDeleted: false,
  },
  loading: false,
  errors: [],
  snackbarMessages: [],
  users: [],
  managers: [],
  leftNavBarDrawer: false,
  repair: {
    rows: [],
    total: 0,
    page: 0,
    pageSize: 5,
    totalPages: 0
  },
};

const mutations = {
  setError(state, error = '') {
    Vue.set(state, 'errors', [...state.errors, error]);
  },
  setMessage(state, message) {
    Vue.set(state, 'snackbarMessages', [...state.snackbarMessages, message]);
  },
  setLoading(state, loading) {
    Vue.set(state, 'loading', loading);
  },
  setTitle(state, payload) {
    Vue.set(state, 'title', payload);
  },
  setUsers(state, payload) {
    Vue.set(state, 'users', payload);
  },
  loginFailure(state) {
    localStorage.removeItem('user');
    Vue.set(state.auth.status, 'loggedIn', false);
    Vue.set(state.auth, 'user', {status: {loggedIn: false}});
  },
  logout(state) {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    Vue.set(state.auth.status, 'loggedIn', false);
    Vue.set(state.auth, 'user', null);
    Vue.set(state.auth, 'token', null);
  },
  updateUser(state, item) {
    localStorage.removeItem('user');
    localStorage.setItem('user', JSON.stringify(item.user));
    localStorage.removeItem('token');
    localStorage.setItem('token', JSON.stringify(item.token));
    Vue.set(state.auth.status, 'loggedIn', true);
    Vue.set(state.auth, 'user', item.user);
    Vue.set(state.auth, 'token', item.token);
  },
  setLeftNavBarDrawer(state, drawer) {
    Vue.set(state, 'leftNavBarDrawer', drawer);
  },
  setRepair(state, payload) {
    Vue.set(state, 'repair', payload);
  },
};

const actions = {
  setError({commit}, payload) {
    commit('setError', payload);
  },
  setLoading({commit}, payload) {
    commit('setLoading', payload);
  },
  setMessage({commit}, payload) {
    commit('setMessage', payload);
  },
  setTitle({commit}, payload) {
    commit('setTitle', payload);
  },

  async login({commit}, payload) {
    try {
      const response = await AuthService.login({
        username: payload.username,
        password: payload.password,
      });

      const token = response.data.accessToken;
      const decoded = jwtDecode(token);
      commit('updateUser', {token: token, user: decoded.userId});
      return true;
    } catch (err) {
      commit('logout');
      return false;
    }
  },
  async findUsers({commit}) {
    try {
      const result = await UsersService.find();
      commit('setUsers', result.data);
    } catch (err) {
      throw err;
    }
  },
  async addUser({commit}, user) {
    try {
      const result = await UsersService.add(user);
      return result;
    } catch (err) {
      throw err;
    }
  },
  async getRepairEntries({commit}, options) {
    try {
      const response = await RepairService.getEntries(options);
      commit('setRepair', response.data);
      return response;
    } catch (err) {
      throw err;
    }
  },
  async fullTextSearchByRepairEntries({commit}, query) {
    try {
      const response = await RepairService.fullTextSearchByEntries(query);
      commit('setRepair', response.data);
      return response;
    } catch (err) {
      throw err;
    }
  },
  async newRepairEntry({}, data) {
    try {
      const response = await RepairService.newEntry(data);
      return response;
    } catch (err) {
      throw err;
    }
  },
  async updateRepairEntry({}, data) {
    try {
      const response = await RepairService.updateEntry(data);
      return response;
    } catch (err) {
      throw err;
    }
  },
  async deleteRepairEntry({}, data) {
    try {
      const response = await RepairService.deleteEntry(data);
      return response;
    } catch (err) {
      throw err;
    }
  },
  async uploadFiles({}, data){
    try {
      const response = await FileService.upload(data)
      return response;
    } catch (err) {
      throw err;
    }
  },
  async deleteFile({}, data){
    try {
      const response = await FileService.remove(data)
      return response;
    } catch (err) {
      throw err;
    }
  },
  logout({commit}) {
    commit('logout');
  },
};

const getters = {
  getterUser: (state) => state.auth.user,
  isLoggedIn: (state) => !!state.auth.status.loggedIn,
  getterTitle: (state) => state.title,
  lastError: (state) => {
    return state.errors.length > 0 ? state.errors[state.errors.length - 1] : '';
  },
  errorsCount: (state) => {
    return state.errors.length;
  },
  lastMessage: (state) => {
    return state.snackbarMessages.length > 0
      ? state.snackbarMessages[state.snackbarMessages.length - 1]
      : '';
  },
  messagesCount: (state) => {
    return state.snackbarMessages.length;
  },
  getUser: state => state.user,
  getUserId: state => (state.user !== null) ? state.user.id : '',
  getUserName: state => (state.user !== null) ? state.user.username : '',
  getUserLastName: state => (state.user !== null) ? state.auth.user.lastName : '',
  getUserFirstName: state => (state.user !== null) ? state.auth.user.firstName : '',
  getUserMiddleName: state => (state.user !== null) ? state.auth.user.middleName : '',
  getUserPhone: state => (state.user !== null) ? state.auth.user.phone : '',
  getUserEmail: state => (state.user !== null) ? state.auth.user.email : '',
  getLoading: state => state.loading,
  getLeftNavBarDrawer: state => state.leftNavBarDrawer,
  getRepairRows: state => (state.repair.rows !== null) ? state.repair.rows : [],
  getRepairTotal: state => state.repair.total,
  getRepairPage: state => state.repair.page,
  getRepairPageSize: state => state.repair.pageSize,
  getRepairTotalPages: state => state.repair.totalPages
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
