/* eslint-disable */
import httpClient from '../wrappers/axios';
import axios from 'axios';
export const AuthService = {
  login,
  getById,
};

async function getById(uuid) {
  try {
    const response = await httpClient.get(
      `/api/users/${uuid}`,  
    );
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
}

async function login(cred) {
  try {
    const result = await axios.post(
      `${process.env.VUE_APP_BACKEND_URI}/auth/login`,
      cred,
    );
    return result
  } catch (err) {
    return Promise.reject(err);
  }
}
