<template>
  <div>
    <v-app>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"
          >Loading</v-progress-circular
        >
      </v-overlay>
      <!-- <v-app id="inspire"> -->
      <v-app-bar app color="primary" dark>
        <!-- <div class="d-flex align-center">
                    <v-img alt="Vuetify Logo" class="shrink mr-2" contain
                        src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png" transition="scale-transition"
                        width="40" />

                    <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100"
                        src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png" width="100" />
                </div> -->
        <v-spacer></v-spacer>
        <v-btn @click="signIn()" text>
          <span class="mr-2">Вход</span>
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>

        <v-btn
          href="https://github.com/vuetifyjs/vuetify/releases/latest"
          target="_blank"
          text
        >
          <span class="mr-2">Latest Release</span>
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-app-bar>
      <v-navigation-drawer
        v-model="leftNavBarDrawer"
        app
        disable-resize-watcher
        disable-route-watcher
        temporary
      >
        <template v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ userFullName }}</v-list-item-title>
              <v-list-item-subtitle>Logged In</v-list-item-subtitle>
            </v-list-item-content>
            <v-btn icon @click="leftNavBarDrawer = !leftNavBarDrawer">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item>
          <v-divider></v-divider>
        </template>
        <v-list dense>
          <v-list-item
            v-for="item in menuItems"
            :to="item.path"
            :key="item.name"
            link
            @click="closeMenu"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app color="primary" dark clipped-left>
        <v-app-bar-nav-icon
          @click.stop="leftNavBarDrawer = !leftNavBarDrawer"
        ></v-app-bar-nav-icon>
        <v-toolbar-title>{{
          $vuetify.breakpoint.mobile
            ? `${appTitleText}`
            : `${appTitleText}`
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{
          $vuetify.breakpoint.mobile ? userFullName : userFullName
        }}</v-toolbar-title>
        <v-btn icon @click="exit()">
          <v-icon>exit_to_app</v-icon>
        </v-btn>
      </v-app-bar>
      <v-main class="pt-0 pt-md-0">
        <router-view />
      </v-main>
      <v-snackbar v-model="errorBar" color="error">
        {{ lastError }}
        <v-btn @click="errorBar = false" dark text>Close</v-btn>
      </v-snackbar>

      <v-snackbar v-model="messageBar" :timeout="timeout" color="teal">
        {{ lastMessage }}
        <v-btn @click="messageBar = false" dark text>Close</v-btn>
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
// const config = require("../config/default.json");
export default Vue.extend({
  name: 'RootView',
  data() {
    return {
      closeOnContentClick: false,
      selected: [],
      errorBar: false,
      messageBar: false,
      timeout: 3000,
      polling: null,
      mini: true,
      appTitleText: process.env.VUE_APP_TITLE_TEXT,
    };
  },
  computed: {
    ...mapGetters({
      lastError: 'lastError',
      errorsCount: 'errorsCount',
      lastMessage: 'lastMessage',
      messagesCount: 'messagesCount',
      loading: 'getLoading',
      getLeftNavBarDrawer: 'getLeftNavBarDrawer',
      username: 'getUserName',
      lastName: 'getUserLastName',
      firstName: 'getUserFirstName',
      middleName: 'getUserMiddleName',
      getAbout: 'getAbout',
    }),
    path() {
      return this.$route.path;
    },
    menuItems() {
      return [
        {
          icon: 'face',
          name: 'Profile',
          path: '/profile',
        },
        {
          icon: 'build',
          name: 'Projects',
          path: '/projects',
        },
      ];
    },
    leftNavBarDrawer: {
      get: function () {
        return this.getLeftNavBarDrawer;
      },
      set: function (newVal) {
        this.setLeftNavBarDrawer(newVal);
        // this.$store.commit(`navbar/setLeftNavBarDrawer`, newVal);
      },
    },
    title() {
      return this.$route.name;
    },
    userFullName() {
      return `${this.lastName} ${this.firstName} ${this.middleName}`;
    },
  },
  watch: {},
  methods: {
    ...mapMutations({
      logout: 'logout',
      setLeftNavBarDrawer: 'setLeftNavBarDrawer',
    }),
    closeMenu() {
      this.leftNavBarDrawer = false;
    },
    exit() {
      this.logout();
      this.$router.push('/login');
    },
    signIn() {
      this.$router.push('/login');
    },
  },
  beforeDestroy() {},
  created() {},
  mounted() {},
});
</script>
<style lang="css" scoped>
#avatar {
  background-color: #009688;
  background-size: cover;
  position: absolute;
  top: 150px;
}

#app .v-bottom-navigation .v-btn {
  height: inherit !important;
}
.v-progress-circular {
  margin: 1rem;
}
</style>
