// import httpClient from '../wrappers/axios';
import {authHeader} from '../_helpers';
import axios from 'axios';
export const FileService = {
  upload,
  remove,
  getImage
};

async function getImage(path) {
  const requestOptions = {
    headers: {
      ...authHeader(),
      'Content-Type': 'image/*'
    }
  };
  return await axios.get(`${process.env.VUE_APP_BACKEND_URI}/upload/${path}`, requestOptions)
}

async function upload(data) {
  const { id, files } = data;
  const requestOptions = {
    headers: {
      ...authHeader(),
      'Content-Type': 'multipart/form-data'
    }
  };
  return await axios.post(`${process.env.VUE_APP_BACKEND_URI}/upload/multi/${id}`, files, requestOptions)
}

async function remove(data) {
  const { id, file } = data;
  const requestOptions = {
    headers: {
      ...authHeader(),
    }
  };
  return await axios.delete(`${process.env.VUE_APP_BACKEND_URI}/upload/${id}/${file}`, requestOptions)
}