import Vue from 'vue';
import VueRouter from 'vue-router';
import LoginPage from '../views/LoginPage.vue';
import RootPage from '../views/Root.vue';
import RepairPage from '../views/RepairPage.vue';
import store from '@/store';

Vue.use(VueRouter)
export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      guest: true,
    },
  },
  {
    path: '/', 
    component: RootPage,
    // redirect: { name: 'RepairPage' },
    children: [
      {
        name: 'RepairPage',
        path: '/repair',
        component: RepairPage
      },
    ]
  },

]

const vueAppAuth = Boolean(process.env.VUE_APP_AUTH);

if (vueAppAuth) { routes.push({ path: '/login', component: LoginPage }) }

export const router = new VueRouter({
  mode: 'history',
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  const authenticated = store.getters['isLoggedIn'];
  if (to.name === 'Login' && authenticated) {
    return next(`/`);
  }

  if (to.name !== 'Login' && !authenticated) next({ name: 'Login' });
  else next();
});

export default router